<template>
    <div class="grid grid-cols-12">
        <div class="col-span-12 lg:col-span-4 text-dark-100">
            <span class="mb-2" v-html="contactData.dental_office"></span>
            <div class="mt-5">
                <a v-if="contactData.phone" :href="`tel:${contactData.phone}`">
                    {{ contactData.phone }}
                </a>
                <br />
                <a v-if="contactData.email" :href="`mailto:${contactData.email}`" class="mt-2 p-link">
                    {{ contactData.email }}
                </a>
            </div>
            <div class="mt-6 lg:mb-5">
                <span class="font-HKGrotesk-Bold"><T t="generic.open_hours" /></span>
                <div class="flex flex-col" v-html="contactData.opening_hours"></div>
            </div>
        </div>
        <div
            class="
                gmap-wrapper
                col-span-12
                lg:col-span-8
                rounded-md
                lg:rounded-3xl
                overflow-hidden
                box-shadow
                aspect-w-3 aspect-h-2
                lg:aspect-w-4 lg:aspect-h-2 lg:mb-[-155px]
                z-20
            "
        >
            <a :href="contactData.google_maps_url" target="_blank">
                <img
                    v-lazy-load
                    :data-src="require('~/assets/images/generic/map_wide.webp')"
                    alt="Térkép"
                    class="w-full h-full object-cover cursor-pointer"
                />
            </a>
        </div>

        <div class="col-span-12 relative text-white pt-0 pb-10 lg:py-7 bg-pseudo z-10">
            <div class="font-HKGrotesk-Bold lg:text-xl mb-6 mt-2"><T t="generic.book_an_appointment" /></div>
            <ButtonLink
                :to="$store.state.globals.medicallConsultationLink"
                is-target-blank
                external
                :label="$t('generic.online_appointment_booking')"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'MapBlock',
    computed: {
        contactData() {
            return this.$store.state.data.contactData[this.$i18n.locale];
        },
    },
};
</script>

<style scoped>
.box-shadow {
    box-shadow: 0 12px 25px rgba(17, 62, 81, 0.1);
}

.bg-pseudo {
    &::before {
        content: '';
        @apply bg-blue w-full h-full absolute left-0 top-0 ml-[-16px] lg:ml-[-45px] rounded-tl-none rounded-tr-none rounded-bl-lg rounded-br-lg lg:rounded-lg -z-1;
        width: calc(100% + 32px);
        height: calc(100% + 120px);
        margin-top: -120px;
        @media (min-width: 1024px) {
            width: calc(100% + 90px);
            @apply h-full mt-0;
        }
    }
}
</style>
