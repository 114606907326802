<template>
    <div class="bg-dark-700 relative overflow-hidden mt-0">
        <picture>
            <source media="(min-width:640px)" :srcset="imgSrc" />
            <img :src="imgSrcMobile" alt="" class="absolute w-full h-full object-cover" />
        </picture>
        <div class="absolute w-full h-full top-0 left-0 bg-dark-700 mix-blend-multiply"></div>
        <div class="container relative pt-15 lg:pt-20 pb-15 lg:pb-24">
            <div
                class="
                    absolute
                    h-full
                    w-full
                    hidden
                    lg:block
                    left-1/3
                    translate-x-1/2
                    2xl:translate-x-2/3
                    3xl:translate-x-1/4
                "
            >
                <div
                    class="
                        aspect-w-9 aspect-h-4
                        top-1/3
                        -translate-y-1/2
                        bg-contain bg-left
                        scale-[160%]
                        3xl:scale-[120%]
                    "
                    :style="`background-image: url(${require('~/assets/images/logos/logo-lg.svg')})`"
                ></div>
            </div>

            <div class="z-10 relative w-full md:w-8/12 lg:w-6/12">
                <div class="text-white">
                    <h1 class="header-1 mb-6 lg:mb-14">{{ title }}</h1>
                    <p class="subtitle mb-28 lg:mb-7">{{ subtitle }}</p>
                </div>

                <ButtonLink
                    v-if="buttonUrl && buttonLabel"
                    :to="buttonUrl"
                    :is-target-blank="buttonType === 2"
                    :external="buttonType === 2"
                    :label="buttonLabel"
                    class="p-button-md/lg xs-w-full"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Hero',
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
        imgSrc: {
            type: String,
            required: true,
        },
        imgSrcMobile: {
            type: String,
            required: true,
        },
        buttonLabel: {
            type: String,
            required: true,
        },
        buttonUrl: {
            type: String,
            required: true,
        },
        buttonType: {
            type: Number, // type 1: open in self, 2: open in new tab
            required: true,
        },
    },
};
</script>

<style scoped></style>
